<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          class="btn btn-secondary"
          @click="$router.push({
            name: 'orders-suppliers-items-edit',
            params: { reference: order.reference }
          })"
        >
          <i class="bi bi-file-earmark-plus fs-4"></i>
          Modidier les articles
        </button>
      </div> 
    </div>
    <hr />
    <div class="row justify-content-between">
      <div class="col-xl col-lg col-md-12 col-12 d-flex">
        <div class="col-auto">Total Quantite :</div>
        <div class="col">
          <span
            v-if="numberItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ numberItems }}
          </span>
          Élément
        </div>
      </div>

      <div class="col-xl col-lg col-md-12 col-12 d-flex">
        <div class="col-auto">Quantité totale :</div>
        <div class="col">
          <span
            v-if="totalQuantityItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ totalQuantityItems }}
          </span>
        </div>
      </div>

      <div class="col-xl col-lg col-md-12 col-12 d-flex">
        <div class="col-auto">Montant Total (TTC*) :</div>
        <div class="col">
          <span
            v-if="totalPriceTTCItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ totalPriceTTCItems }}
          </span>
          DH
        </div>
      </div>
    </div>
    <hr />
    <br />
    <div class="row">
      <div class="col-xl-3 col-lg-3 col-md-6 col-12 my-3">
        <label for=""> Fournisseur <span class="text-danger">*</span> </label>

         <vue-select 
          :options="suppliers"
          v-model="order.supplier_reference"
          placeholder="Select an option"
          labelColumn="society"
          valueColumn="reference"
        />

      </div>

      <div class="col-xl-3 col-lg-3 col-md-6 col-12 my-3">
        <label for="">Date <span class="text-danger">*</span></label>
        <input type="date" v-model="order.date" class="form-control" />
      </div>
      
      <div class="col-xl-3 col-12 my-3 ">
        <label for="">
          Livraison au Client 
        </label>
        <vue-select 
          :options="customers"
          v-model="customer_reference"
          placeholder="Select an option"
          labelColumn="fullName"
          valueColumn="reference"
          @input="onChangeDeliveryAddress(customer_reference)"
        />
      </div>

      <div class="col-xl-3 col-12 my-3 ">
        <label for="">
          Facture au Client 
        </label>
        <vue-select 
          :options="customers"
          v-model="customer_reference"
          placeholder="Select an option"
          labelColumn="fullName"
          valueColumn="reference"
          @input="onChangeBillingAddress(customer_reference)"
        />
      </div>

      <div class="col-xl-6 col-lg-6 col-md-6 col-12 my-3">
        <label for=""> Adresse de livraison <span class="text-danger">*</span></label>
        <textarea  v-model="order.deliveryAddress" rows="6" class="form-control" ></textarea>
        
      </div>

      <div class="col-xl-6 col-lg-6 col-md-6 col-12 my-3">
        <label for=""> Adresse de facturation <span class="text-danger">*</span></label>
        <textarea  v-model="order.billingAddress" rows="6" class="form-control" ></textarea>
      </div>
    </div>

    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-xl-8 col-lg-8 col-md-12 col-12">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives à la commande (fournisseur,
            Quantité totale, Mantant totale, Nombre d'articles, ...) et
            correctes. <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-xl-auto col-auto">
        <button
          class="btn btn-success"
          @click="save(order)"
          :disabled="!accept ? true : false"
        >
          <i class="bi bi-check-square-fill"></i> Enregistrer les modifications
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        (<span class="text-danger">*</span>) : Ce champ est obligatoire.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import order from "../../../store/modules/order";

export default {
  data() {
    return {
      accept: false,
      customer_reference:''
    };
  },
  computed: {
    ...mapGetters("order", {
      order_items: "getOrderSupplierItems",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
    }),
    ...mapGetters("order", {
      order: "getSupplierOrder",
    }),
    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("order/updateSupplierOrder", data);
      await setTimeout(((this.order = {}), (this.accept = false)), 2000);
    },
    async refresh() {
      await this.$store.dispatch("order/getAllItems");
      await this.$store.dispatch("supplier/getAll");
      await this.$store.dispatch("customer/getAll");
    },
    async onChangeDeliveryAddress(customer_reference) {
      var customer = this.customers.find(p => p.reference == customer_reference)
      this.order.deliveryAddress = `${customer.fullName}
        ${customer.address ? customer.address : ''}
        ${customer.city ? customer.city : ''}
        ${customer.zipCode ? customer.zipCode : ''}
        ${customer.phone ? customer.phone : ''}`;

    },
    async onChangeBillingAddress(customer_reference) {
      var customer = this.customers.find(p => p.reference == customer_reference)
      this.order.billingAddress = `${customer.fullName}
        ${customer.address ? customer.address : ''}
        ${customer.city ? customer.city : ''}
        ${customer.zipCode ? customer.zipCode : ''}
        ${customer.phone ? customer.phone : ''}`;
    }
  },
  async beforeMount() {
    
    await this.$store.dispatch("order/getAllItems");
    await this.$store.dispatch("supplier/getAll");
    await this.$store.dispatch("customer/getAll");

    await this.$store.dispatch(
      "order/showSupplierOrder",
      this.$route.params.reference
    );
   
  },
  
};
</script>
